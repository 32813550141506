import React, { Component } from 'react';
// import '/node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
// import data from './data/demodata.json';
import Question from './components/question';
import Answer from './components/answer';
import Result from './components/result';

class App extends Component {

    state = {
        start: true,
        popUp: '',
        acceptTerms: false,
        questions: [],
        result: false,
        resultData: [],
        dataString: '[0][_children]',
        // dataTable: data[0]['_children'],
        dataTable: '',
        dataTableInit: '',
        view: 'QA'
    }

    getArrayByStringName = (array, name) => {
        // Splitting the string into parts
        const parts = name.split(/[[\]']+/).filter(Boolean);

        // Iterating through the parts to access the array
        let result = array;
        for (let part of parts) {
          if (part in result) {
            result = result[part];
          } else {
            // Error handling for the case where the name is invalid
            console.error(`Part '${part}' not found in the array.`);
            return null;
          }
        }
        return result;
      }

    addQuestion = (path,aUid) => {
        const anUid = aUid;

        // Path to Navigate
        let currentQuestion = this.state.questions;
        //console.log(this.state.questions)
        let existQuestion = this.state.questions.find(question => question.path === this.state.dataString);
        if (existQuestion) {
            // nothing to do
        } else {
            currentQuestion.push(
                this.state.dataString
            );
        }
        this.setState({ questions: currentQuestion });
        
        // Switch to Question
        let resultArray = this.getArrayByStringName(this.state.data, path);
        if (resultArray.length) {
            this.setState({ result: false });
            this.setState({ dataString: path });
            this.setState({ dataTable: resultArray });
            this.setState({ view: 'QA' });
        } else {
            //console.log(this.state.dataTable);
            this.setState({ result: true });
            this.setState({ answer: anUid });
            this.setState({ view: 'RL' });
            // console.log('Aktuelle Antwort-ID:'+aUid);
        }
    }

    jumpToQuestion = (path,i) => {
        // console.log(this.state.questions)
        // console.log(path);
        // Switch to Question
        let resultArray = this.getArrayByStringName(this.state.data, path);
        if (resultArray.length) {
            this.setState({ result: false });
            this.setState({ dataString: path });
            this.setState({ dataTable: resultArray });
        } else {
            this.setState({ view: 'RL' })
        }

        // Update this.state.questions
        let stateArray = this.state.questions;
        const newArray = stateArray.slice(0,i);
        // console.log('new'+newArray);

        // console.log(i);
        
        this.setState({ questions: newArray });
        // console.log(this.state.questions);

        this.setState({ view: 'QA' })
    }

    goToStart = () => {
        this.setState({
            start: true,
            questions: [],
            result: false,
            resultData: [],
            dataString: '[0][_children]',
            dataTable: this.state.dataTableInit,
            view: 'QA'
        })
    }

    componentDidMount() {
        // console.log( this.props.dataPid )
        
        const url = `https://www.hasit.de/testpages/productfinder?tx_fixitgruppe_productfindertreejson[pageUid]=${this.props.dataPid}&type=1702386624`

        // console.log(url);
        fetch(url)
        .then((response) => response.json())
        .then((data) => {
            this.setState({ data: data })
            this.setState({ dataTable: data[0]['_children'] })
            this.setState({ dataTableInit: data[0]['_children'] })
        })
        .then(console.log)
        .catch((error) => {
            // console.log(error);
            this.setState({ error: true })
        });

        // console.log('DataTable: '+this.state.dataTable )
    }

    updateView = (newValue) => {
        this.setState({ view: newValue });
    }

    changeView = (newValue) => {
        this.setState({ view: newValue });
    };

    openPopUp = () => {
        this.setState({ popUp: 'open' });
    };

    render() {
        // console.log(this.state.dataTable);
        return (
            <div className="App">
                <div className='container'>

                    <div className='row'>
                        <div className='col-12 text-center headline'>
                            <div className='headline-icon'>
                                <svg viewBox="0 0 32.5 59" className="svg-icon">
                                    <path d="M31.63 30.836H.86a.865.865 0 0 0-.86.875v5.759a.879.879 0 0 0 .42.75l5.08 3.071L.51 57.872a.888.888 0 0 0 .13.774.872.872 0 0 0 .7.354h29.82a.87.87 0 0 0 .83-1.128L27 41.291l5.08-3.071a.879.879 0 0 0 .42-.75v-5.759a.873.873 0 0 0-.87-.875zm-1.64 26.415H2.51L7.04 42.22l8.76 5.292a.873.873 0 0 0 .9 0l8.76-5.292zm.78-20.277l-14.52 8.768-14.52-8.768v-4.388h29.04v4.388zm.86-27.27H.86a.865.865 0 0 0-.86.875v17.5a.865.865 0 0 0 .86.875h30.77a.873.873 0 0 0 .87-.875v-17.5a.873.873 0 0 0-.87-.879zm-.86 17.5H1.73v-15.75h29.04V27.2zm.86-27.2H.86A.864.864 0 0 0 0 .878v6a.864.864 0 0 0 .86.874h30.77a.873.873 0 0 0 .87-.874v-6A.872.872 0 0 0 31.63 0zm-.86 6H1.73V1.752h29.04V6z"></path>
                                </svg>
                            </div>
                            <h1>HASIT Produktfinder</h1>
                        </div>
                    </div>

                    {this.state.start ? (
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <p>Sie suchen noch die passenden Produkte für Ihr Projekt?</p>
                                <p>Beantworten Sie einfach die folgenden Fragen und Sie erhalten eine Liste mit unseren Empfehlungen - optimal auf Ihre Projekt-Bedingungen zugeschnitten.</p>
                                
                                {this.state.acceptTerms 
                                    ? <button onClick={()=>{this.setState({start:false})}} className='btn btn-blue mt-4'>Produktfinder starten</button>
                                    : <button onClick={this.openPopUp} className='btn btn-blue mt-4'>Produktfinder starten</button>
                                }
                                {this.state.popUp === 'open' ? (
                                    <div className='popUp'>
                                        <p>
                                            <b>Produktfinder:<br />
                                        Finden Sie das passende Produkt für Ihren Einsatz-Zweck.<br />
                                        Unser Produktfinder hilft Ihnen dabei.</b><br />
                                        Für die Nutzung des Produktfinder bestätige ich hiermit die <a href="">AGB</a> und <a href="">Nutzungsbedingungen</a>.</p>
                                        <button onClick={()=>{this.setState({start:false,acceptTerms:true,popUp:''})}} className='btn btn-blue'>Akzeptieren</button>
                                    </div>
                                ) : ''}
                            </div>
                        </div>
                    ):(
                        <>
                            {!this.state.result ? (
                                <>
                                {this.state.dataTable.map((items,index) => {
                                    return (
                                        <div className='row answer-container' key={items.uid}>
                                            <Question title={items.title} />
                                            {items._children.map((a,aIndex) => {
                                                return <Answer 
                                                    key={a.uid}
                                                    onAdd={() => this.addQuestion(this.state.dataString +'[0][_children]['+ aIndex+'][_children]',a.uid)}
                                                    title={a.title}
                                                    aUid={a.uid}
                                                    image={a.tx_fixitsitepackage_navimage} description={a.productfinder_description} 
                                                    link="next" />
                                            })}
                                        </div>
                                    );
                                })}
                                </>
                            ) : (
                                <div className='container'>
                                    <Result aUid={this.state.answer} view={this.state.view} changeView={this.updateView} />
                                </div>
                            )}
                        </>
                    )}

                    <div className='nav-container'>
                        {!this.state.start 
                            ? <button className="nav-btn" onClick={() => this.goToStart()}><span>neu starten</span></button>
                            : ''
                        }

                        {this.state.questions.length ? (
                                <>
                                    {this.state.questions.map((question,i) => {
                                        return (
                                                <button key={i} className="nav-btn" onClick={() => this.jumpToQuestion(question, i)}>
                                                    <span>Frage {i+1}</span>
                                                    {/* {i === 0 ? (
                                                        <span>neu starten</span>
                                                    ) : (
                                                        <span>Frage {i+1}</span>
                                                    )} */}
                                                </button>
                                        )})
                                    }
                                    {this.state.view === 'RD' ? (
                                        <>
                                            <button className="nav-btn" onClick={() => this.changeView('RL')}>
                                                <span>zurück zum Ergebnis</span>
                                            </button>
                                        </>
                                    ) : ''}
                                </>
                            ) : ''
                        }
                        
                        {!this.state.start 
                            ? <button className='nav-btn active'></button>
                            : ''
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default App;
