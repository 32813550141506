import React, { Component } from 'react';
// import resultData from '../data/resultProduct.json';
// import resultData from '../data/resultData.json';
import Product from './product';
import ProductDetail from './productDetail';

class Result extends Component {

    state = {
        resultData: '',
        product: ''
    }

    componentDidMount() {

        const url = "https://www.hasit.de/?id=14598&type=1699552000&tx_fixitgruppe_productfindertreeresults[pageUid]="+this.props.aUid

        console.log(url);
        fetch(url)
        .then((response) => response.json())
        .then((data) => {
            console.log('DATA:' + data);
            this.setState({ resultData: data })
        })
        .then(console.log)
        .catch((error) => {
            console.log(error);
            this.setState({ error: true })
        });
    }

    productDetail = (item) => {
        this.setState({ product: item });
        this.props.changeView('RD');
    }

    updateView = (newValue) => {
        this.setState({ view: newValue });
        // this.setState({ result: true })
    }

    render () {
        return (
            <div className='container'>
                {this.state.resultData ? (
                <div className='row result'> 
                    {this.props.view === 'RD' ? (
                        <>
                            <div className='question'>
                                {this.state.resultData.title.length 
                                    ? <h2>{this.state.resultData.title}</h2>
                                    : <h2>Ihre Produktauswahl</h2>
                                }
                            </div>
                            <div className='product-detail'>
                                <ProductDetail 
                                    item={this.state.product} 
                                    result={this.state.resultData}
                                    />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='question'>
                                {this.state.resultData.title.length 
                                    ? <h2>{this.state.resultData.title}</h2>
                                    : <h2>Ihre Produktauswahl</h2>
                                }
                            </div>
                            {this.state.resultData.products.map((item, index) => (
                                <Product 
                                    key={item.product.uid} 
                                    item={item}
                                    view={this.state.view}
                                    changeView={this.updateView}
                                    showDetail={() => this.productDetail(item)} />
                            ))}
                        </>
                    )}
                </div>
                ):(
                    <>
                    {this.state.error 
                        ? <span className='error'>Es ist ein Fehler aufgetreten!</span>
                        : (
                            <div className='row'>
                                <div className='col-12'>
                                    <div className="lds-ring">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    </>
                )}
            </div>
        );
    }
}

export default Result;