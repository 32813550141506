import React, { Component } from 'react';

class Product extends Component {
    state = { }

    render() { 
        return (
            <div onClick={this.props.showDetail} className="col-md-4">
                <button className="card">
                    <div className="card-img">
                        {this.props.item.label.length
                            ? <div className='label'>{this.props.item.label}</div>
                            : ''
                        }
                        {this.props.item.product.isProductSystem 
                            ? <img src={this.props.item.product.image+'-FJPG-S540'} className="card-img-top system" alt="..." />
                            : <img src={this.props.item.product.image+'-FJPG-S540'} className="card-img-top product" alt="..." />
                        }
                        
                    </div>
                    <div className="card-body">
                        <div className='card-text'>
                            <p className="title">{this.props.item.product.title}</p>
                            <p className='short-description'>{this.props.item.product.shortDescription}</p>
                            <p className="description">{this.props.item.product.description}</p>
                        </div>
                    </div>
                </button>
            </div>
        );
    }
}
 
export default Product;