import React, { Component } from 'react';
import ProductSlider from './productSlider';

class ProductDetail extends Component {
    state = { }

    render() { 
        return (
            <div className="row">
                <div className='col-md-4 detail-img'>
                    <div className='inner'>
                        {this.props.item.label.length
                            ? <div className='label'>{this.props.item.label}</div>
                            : ''
                        }
                        <img src={this.props.item.product.image+'-FJPG-S540'} className="card-img-top" alt="..." />
                    </div>
                </div>
                <div className='col-md-8'>
                    <h2>{this.props.item.product.title}</h2>
                    <div className='shortDescription'>
                        {this.props.item.product.shortDescription}
                    </div>
                    <div className='advantages row'>
                        {this.props.item.product.advantages.map((item, index) => (
                            <>
                            {index <= '2' ? (
                                    <div key={index} className='col item'>
                                        <div className='inner'>
                                            {item}
                                        </div>
                                    </div>
                                ) : ''
                            }
                            </>
                        ))}
                    </div>
                </div>
                <div className='col-12 buttons'>
                    {this.props.item.product.link 
                        ?   <a className="btn btn-secondary cta" href={this.props.item.product.link} target="_blank">
                                {this.props.item.product.isProductSystem
                                    ? 'System-Details'
                                    : 'Produkt-Details'
                                }
                            </a>
                        : ''
                    }
                    {this.props.result.specialistDealerSearchUrl 
                        ? <a className="btn btn-secondary cta" href={this.props.result.specialistDealerSearchUrl} target="_blank">Dealer-link</a>
                        : ''
                    }
                    {this.props.result.contactFormUrl 
                        ? <a className="btn btn-secondary cta" href={this.props.result.contactFormUrl} target="_blank">Contact-link</a>
                        : ''
                    }
                </div>
                
                {this.props.item.product.relatedProducts.length && this.props.item.product.isProductSystem
                    ? <ProductSlider products={this.props.item.product.relatedProducts} />
                    : ''
                }

            </div>
        );
    }
}
 
export default ProductDetail;