import { Component } from "react";
import parse from 'html-react-parser';

class Answer extends Component {
    state = {}
    render(){
        return (
            <div className="col-md-4">
                <button onClick={this.props.onAdd} className="card">
                    <div className="card-img">
                        <img src={this.props.image} className="card-img-top" alt="..." />
                    </div>
                    <div className="card-body">
                        <div className='card-text'>
                            <h4 className="title">{this.props.title}</h4>
                            {this.props.description ? (
                                parse(`${this.props.description}`)
                            ):(
                                <></>
                            )}
                        </div>
                    </div>
                </button>
            </div>
        );
    }
}

export default Answer;