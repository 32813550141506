import React, { Component } from "react";
import Slider from "react-slick";

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
        <div className='col-12'>                
            <div className='row related-products'>
                <h3>Die Produkte im System</h3>
                <Slider {...settings}>
                    {this.props.products.map((item, index) => (
                        <div key={index} className='card product-card'>
                            <a className='prod-link' href={item.link} target="_blank">
                                <div className='prod-img'>
                                    <img src={item.image+'-FJPG-S270'} className="card-img-top" alt="..." />
                                </div>
                                <div className='prod-title'>
                                    {item.title}
                                </div>
                            </a>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
  }
}