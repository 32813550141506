import { Component } from "react";

class Question extends Component {
    state = {}
    render(){
        return (
            <div className="question">
                <h2>{this.props.title}</h2>
            </div>
        );
    }
}

export default Question;